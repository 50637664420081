const downloadFile = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function getStaticUrl(host: string, code: string) {
  const res = await fetch(
    `${host}/landing-statistic-by-code/landing-statistic-by-code?code=${code}`
  )
  console.log('domain:', host, ', code:', code, ', res:', res)
  const data = await res.json()
  console.log('data:', data)

  if (!data.data) return null

  const idMatch = data.data.parameterString.match(/id:"([^"]*)"/)
  const id = idMatch ? idMatch[1] : null

  const ckMatch = data.data.parameterString.match(/ck:"([^"]*)"/)
  const ck = ckMatch ? ckMatch[1] : null

  if (id && ck) {
    console.log('id:', id, 'ck', ck)

    return { id, ck }
  } else {
    console.error('Match not found')
  }
}

const ele = `<div class="w-max flex flex-col items-center bg-black/[0.7] h-max rounded fixed inset-0 p-2 m-auto">
<div class="spinner"></div>
<div class="text-xs text-gray-300 mt-2 w-full">应用下载中，请稍候</div>
</div>`

let clickEnabled = true

export async function onClickDownload() {
  if (clickEnabled === false) return

  const host = window.location.host
  const code = (
    window.location.hash ? window.location.hash : window.location.search
  )
    .replace(/^(#([^?]+))?(\?(.+))?$/, '$3')
    .replace(/\?/, '&')
  // const info = parser();
  // const payload = window.btoa(JSON.stringify({ ua: info.ua, os: info.os, cpu: info.cpu, device: info.device, }));
  let device = 'ANDROID'
  if (/android/i.test(window.navigator.userAgent) === false) {
    device = 'WEB'
  }

  var elem = document.createElement('div')
  elem.className = 'loading'
  elem.innerHTML = ele
  document.body.appendChild(elem)

  const hostParam =
    // 檢查window.location.href是否包含'.html'，是的話就找出最後一個/的位置，並取出前面的字串
    window.location.href.indexOf('.html') > -1
      ? window.location.href.slice(0, window.location.href.lastIndexOf('/'))
      : // 檢查window.location.href的最後一個字元是否為'/'，是的話就去掉
      window.location.href.slice(-1) === '/'
      ? window.location.href.slice(0, -1)
      : window.location.href

  const downloadUrl = `${hostParam}/download?host=${host}${code}&device=${device}`
  if (downloadUrl) {
    clickEnabled = true
    downloadFile(downloadUrl)
  } else {
    clickEnabled = false
  }

  // @ts-ignore
  LA.track('download')

  const currentUrl = hostParam
  if (currentUrl !== 'undefined' && currentUrl.indexOf('code') !== -1) {
    const copyText = currentUrl.split('?code=')[1].substring(0, 6)
    navigator.clipboard.writeText(copyText)
  }

  setTimeout(() => {
    document.body.removeChild(elem)
  }, 1000)
}

export function getSrc(str: string) {
  if (!str) return ''
  const i = Number(str.substring(0, 3).replace('p', ''))
  const start = i + 3
  const a = str.slice(3, start)
  const b = str.slice(start + 16)
  return `${a}${b}`
}
