import { useState, useEffect } from 'react'
import { onClickDownload, getSrc } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import './index.css'

const imgURLs = [
  // 01
  '31bf3ad5-257f-44f4-8abc-e7d436c2c5fe',
  // sticky header
  '8b695fd5-d363-40d3-9ed5-014acf54d5a0',
]

function Landing1(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="m-auto flex flex-col bg-[#210227] md:max-w-[640px]"
      onClick={onClickDownload}>
      {/* sticky header */}
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[1]}`}
        className="sticky top-0 w-full"
      />
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[0]}`}
        className="w-full"
      />
    </div>
  )
}

export default Landing1
